.icon_button{
    height: 3vh;
}
.buttonMargin {
    margin-right: 5px;
}
.cardText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines, adjust as needed */
    -webkit-box-orient: vertical;
}
.cardImg{
    width: 100%; /* Make image fill the container width */
    height: 20vh; /* Set a fixed height or use percentage */
    object-fit: cover;
    object-position: center;
}
.expanded {
    -webkit-line-clamp: unset;
    height: auto;
}
.projectCard {
    display: flex;
    flex-direction: column;
}

.card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}